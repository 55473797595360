import * as React from "react";
import { createRoot} from "react-dom";

import {Card, Typography , makeStyles} from '@material-ui/core';

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");

if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {

    const useStyles = makeStyles({
        card: {
            margin: 'auto',
            width: '50%',
            padding: 10,
            color: 'white', 
            backgroundColor: '#282F3F',
        }, 
        logo: {
            position: 'relative', 
            left: '25%',
            width: '50%'
        }, 
        text: {
            fontSize: '1.4rem',
            textAlign: 'center',
        }, 
        padding: {
            padding: 10
        }
    });

    function NotSupported(){
        const classes = useStyles();
        return (
            <Card raised className={classes.card}>
                <img src="assets/images/common/tocalabs.svg" className={classes.logo} />
                <div className={classes.padding} />
                <Typography className={classes.text}>We are sorry, Internet Explorer cannot display this webpage. Please use a different browser such as Chrome, Edge, Safari or Firefox.</Typography>
            </Card>
        );
    }

    const root = createRoot(document.querySelector("#appRoot"));

    root.render(
       <NotSupported/>
    );
}

